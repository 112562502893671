/* ------------------------------------------ Single Page-Based Functions ------------------------------ */

/* functions to expand notes items */
function expandAllNotesRows() {
	// if the class "expanded" IS NOT present click the open rown icon (plus sign, currently)
	$("table.dataTable tr td")
		.find(".notesControlAll:not(.expanded)").trigger("click").end()
		.find(".notesControl:not(.expanded)").trigger("click");
}
/* Close all open Notes rows */
function closeAllNotesRows() {
	// if the class "expanded" IS present click the open rown icon (plus sign, currently)
	$("table.dataTable tr td")
		.find(".notesControlAll:is(.expanded)").trigger("click").end()
		.find(".notesControl:is(.expanded)").trigger("click");
}
function showAllAuditTrails() {
	expandAllNotesRows();
	$("table.dataTable tr.notes td .openHistoryLink:not(.expanded)").trigger("click");
}
function showAllMaps() {
	expandAllNotesRows();
	$("table.dataTable tr.notes td .locationMapLink:not(.expanded,[data-inout])").trigger("click");
}
function showAllPhotoTimestamps() {
	expandAllNotesRows();
	$("table.dataTable tr.notes td .snapshotToggleLink:not(.expanded)").trigger("click");
}

function ChangeMaxRows(el,maxRowsLink) {
	pleaseWait();
	document.location=maxRowsLink.replace('[MaxRowsReplace]',el.value)+"&StartRow=1";
}

// HourlyEditAlert.cfm
function populateDateTimeInputs(dID,hID,mID,ampmID,d,h,m) {
	$("#"+dID).val(d).trigger("blur");
	populateTimeInputs(hID,mID,ampmID,h,m);
}

// HourlyEditAlert.cfm
function populateTimeInputs(hID,mID,ampmID,h,m) {
	var ampm = "AM"
	if (h >= 12) ampm = "PM";
	if (h > 12) h = h - 12;
	if (h == 0) h = 12;
	$("#"+hID+"Input").val(formatAsTwoDigits(h)).trigger("blur");
	$("#"+mID+"Input").val(formatAsTwoDigits(m)).trigger("blur");
	$("#"+ampmID+"Control")
		.removeClass("AM PM")
		.addClass(ampm);
	$("#"+ampmID).val(ampm);
	startTimeControlHide();
	$('.timeDropDown').hide();
}

function validateCreditCardNumber(fieldSelector, event) {
	var cc = $(fieldSelector).val();
	if (cc.length < 15 || cc.length > 16 || !$.isNumeric(cc)) {
		alertDialog({
			msg:getTranslation("JavaScript.InvalidCreditCard"),
			okay: function() {
				$(fieldSelector).floatBorder();
				setTimeout(function(){
					$(fieldSelector).focus();
				},200);
			}
		});
		event.preventDefault();
		event.stopPropagation();
	}
	else
		return true;
}

// Entries
function CheckModificationDescription() {
	if ($.trim($("#ModificationDescription").val()) == "") {
		$("#ModificationDescriptionWarning").fadeIn(500);
		$("#ModificationDescription").trigger("select");
		return false;
	}
	else {
		$("#ModificationDescriptionWarning").slideUp(500);
		return true;
	}
}

/* ------------------------------------------ Ready Function ------------------------------ */
$(document).ready(function () {

	/* ------------------------------------------ Global Visual Functions ------------------------------ */
	$.fn.extend({
		highlightValueAboveNByClass: function(val,classname) {
			$(this).find("."+classname).each(function(){
				if (parseFloat($(this).text()) > val)
					$(this).addClass("redText");
			});
			return $(this);
		},

		addTotalsColumnAfterHeaderName: function(headerName,classname,callback) {
			var hrs = 0,
				classSelector = ".".concat(classname.split(" ").join(".")),
				iLast = $(this).find("tbody > tr > td"+classSelector).length - 1,
				hasDollarSign = false,
				justChanged = false,
				isNegative = false;
			$.each($(this).find("tbody > tr > td"+classSelector).get().reverse(),function(i,node){
				var td = $(this),
						tr = td.closest("tr"),
						$hrs = tr.find("td.Hours"),
						thisAlt = tr.is(".even"),
						deleted = tr.data("isdeleted") == true ? true : false,
						aboveAlt = tr.prev().is(".even"),
						thisHours = unformatMoney($hrs.text());
				// writing operation
				if (!hasDollarSign && $hrs.text().indexOf("$") != -1)
					hasDollarSign = true;
				if (!deleted)
					hrs += thisHours;
				if (thisAlt != aboveAlt || i === iLast || tr.prev().is(".bar,.skip")) {
					if (hasDollarSign) {
						td.text(formatMoney(hrs));
					} else {
						//td.text(roundNumToPlaces(hrs, 2).toFixed(2));
						// agalano: 2022-08-09 replaced the total with the toggleHrsMins() so that it toggles with the rest of the page
						td.html(toggleHrsMins(hrs,g.clientSettingsObj.numberDisplayFormat,'no_format'));
					}
					if (!justChanged && i != 0)
						td.css({"border-bottom":"transparent 1px solid"});
					if (td.text().indexOf("($") != -1 || td.text().indexOf("-") != -1)
						td.toggleClass("redText",true);
					// reset hrs and justChanged flag
					hrs = 0;
					justChanged = true;
				}
				else {
					if (!deleted){
						td.html('<div class="text-left"><i class="fas fa-sm font-charcoal fa-arrow-up-right"></i></div>');
					}
					if (!justChanged && i != 0)
						td.css({"border-bottom":"transparent 1px solid"});
					justChanged = false;
				}
			});
			if (typeof callback === "function") callback();
			return $(this);
		},

		cloneForm: function() {
			var n = $(this).attr("name");
			g.formClone[n] = new Object();
			$(this).find("input[type=text][name]:not([class*=ignoreChanges]),input[type=hidden][name][class!=ignoreChanges]").each(function(){
				g.formClone[n][$(this).attr("name")] = $(this).val();
			});
			$(this).find("input[type=checkbox][name]:not([class*=ignoreChanges])").each(function(){
				g.formClone[n][$(this).attr("name")] = $(this).is(":checked");
			});
			$(this).find("input[type=radio][name]:checked:not([class*=ignoreChanges])").each(function(){
				g.formClone[n][$(this).attr("name")] = $(this).val();
			});
			$(this).find("select[name]:not([class*=ignoreChanges])").each(function(){
				g.formClone[n][$(this).attr("name")] = $(this).find("option:selected").val();
			});
			$(this).find("textarea[name]:not([class*=ignoreChanges])").each(function(){
				g.formClone[n][$(this).attr("name")] = $(this).val();
			});
		}
	});

});

/* ------------------------------------------ Ready Functions ------------------------------ */

/* ------------------------------------------ Ajax Ready ------------------------------ */


// Listeners for showing Audit History and Employee Requests
$(document).ready(function(){
	/**
	*	Opens the record history audit trail inside the Timesheet table
	* 	Replacement for: openHistory(displayMode,id,viewerUserID,$link) {}
	*/
	$(".record-history").on("click", function(e){
		e.preventDefault();
		e.stopPropagation();

		var id = $(this).data("id");
		var recordType = $(this).data("record-type");
		var viewerUserID = $(this).data("vu-id");
		var url = g.baseApiUrl + '/record/' + recordType + '/history/html';

		if ($("#history"+id).is(":hidden")) {
			$("#history"+id).html('<div style="text-align:left;padding:5px 0px;" id="historyAjaxLoader'+id+'"><div class="ajaxLoader ajaxLoaderIcon" style="margin-left:5px;"></div></div>').slideDown(50);
			$.ajax({
				type: "GET",
				url: url + "/" + id,
				headers: createBaseApiHeaders(getApiKeyFromStorage(),getApiTokenFromStorage()),
				dataType: "html",
				success: function(data) {
					$("#history"+id).html( JSON.parse(data) );
					$("#historyTable"+id+" tr td").show(); // ensure td's are shown in IE6
					$("#historyTable"+id+" > tbody > tr.requestRow").hide();
					$("#historyTable"+id).showChangesInTable();
					setZebraStripes();
					if ($("#historyTable"+id+" > tbody > tr.requestRow").length > 0) {
						$("#historyRequestControl"+id).show();
					}
					$(this).toggleClass("expanded",true);
				},
				error: function(jqXHR, textStatus, errorThrown){
					processAjaxError(jqXHR, textStatus, errorThrown);
				}
			});
		}
		else {
			$("#historyTable"+id+" > tbody > tr.requestRow").hide();
			$("#historyRequestControl"+id).hide();
			$("#historyRequestControlText"+id).html("Show");
			$("#history"+id).hide();
			$(this).toggleClass("expanded",false);
		}
	});

	/**
	*	Replacement for showHistoryRequests(id)
	*/
	$(".record-history-requests").on("click", function(e){
		var id = $(this).data("id");
		$("#historyTable"+id+" > tbody > tr.requestRow").toggle();
	});
});


// Show/Hide for Customer and Vendor List pages
$(document).ready(function(){

	$(".customer-show-hide").on("click", function(e){
		e.preventDefault();
		e.stopPropagation();
		// old jq: $('.CustomerExtra').toggle();
		// but we want a grid display rather than block when toggling

		var displayMode = $(".CustomerExtra").css("display");

		if(displayMode == "none"){
				$(".CustomerExtra").css("display", "grid");
		}
		else{
				$(".CustomerExtra").css("display", "none");
		}
	});
	$(".vender-show-hide").on("click", function(e){
		e.preventDefault();
		e.stopPropagation();
		$('tr.VendorExtraRow').toggle();
	});

});

/**
 *  Loop through all edited hourly or project to be loaded
 *	@return void - passed id and type to loadDisplayRecord
 */
function loadAllEditedRecords() {
	let allRecords = $(".recordControl");
	for(var i = 0; i < allRecords.length; i++){
		let tsType = (allRecords[i].dataset.tsType == 1) ? "hourly" : "project"; // 1 = hourly, 4 = project
		loadDisplayRecord(allRecords[i].dataset.recordId, tsType);
	}
}
/**
 *	Get info for an edited record to be loaded
 *	@param {object} e - event object
 *	@return void - passes id and type to loadDisplayRecord
 */
function loadEditedRecords(e) {
	let RecordID = e.target.dataset.recordId;
	let tsType = (e.target.dataset.tsType == 1) ? "hourly" : "project"; // 1 = hourly, 4 = project
	loadDisplayRecord(RecordID, tsType);
}

/**
 * 	Loads a given record and displays it on the appropriate timesheet
 *
 * 	@param id - id of single edited item to load
 * 	@param {int} type - 1 = hourly, 4 = project
 * 	@return void - loads up the record via api and displays it to the timesheet
 */
function loadDisplayRecord(id, type){
	let editedRecordsURL = g.baseApiUrl + "/record/" + type + "/history/html/" + id;
	// console.log("%c loadEditedRecords(), url = ", "color:green;", editedRecordsURL);
	$.ajax({
		type: "GET",
		url: editedRecordsURL,
		headers: createBaseApiHeaders(getApiKeyFromStorage(),getApiTokenFromStorage()),
		success: function(result) {
			// console.log("loadDisplayRecord(), ajax success, result = ", result);
			$("#editedRecord_"+id).empty();
			$("#editedRecord_"+id).append(result);
		},
		error: function(jqXHR, textStatus, errorThrown){
			processAjaxError(jqXHR, textStatus, errorThrown);
		}
	});

}
