// main downloader
$.fn.downloader = function(type, fileid, historyid, getparams) {

	// url types
	var urls = {
		expense: `${g.baseApiUrl}/record/expense/receipt/${fileid}`,
		expensehistory: `${g.baseApiUrl}/record/expense/receipt/${fileid}?HistoryID=${historyid}`,
		payrollperiod_pdf: `${g.baseApiUrl}/report/download/payrollperiod/${fileid}${getparams}`,
		payrollfile: `${g.baseApiUrl}/report/download/payrollperiod/${fileid}${getparams}`,
		purchasereceipt: `${g.baseApiUrl}/receipt/download/purchase/${fileid}`,
		recurringpaymentreceipt: `${g.baseApiUrl}/receipt/download/recurring/${fileid}`,
		projectrates_default: `${g.baseApiUrl}/file/download/project-rates/default`,
		projectrates_user: `${g.baseApiUrl}/file/download/project-rates/user`,
		projectrates_user_individual: `${g.baseApiUrl}/file/download/project-rates/user/individual`,
		accountcoderates_default: `${g.baseApiUrl}/file/download/accountcode-rates/default`,
		accountcoderates_user: `${g.baseApiUrl}/file/download/accountcode-rates/user`,
		accountcoderates_user_individual: `${g.baseApiUrl}/file/download/accountcode-rates/user/individual`,
		calendar_schedule_ics: `${g.baseApiUrl}/file/download/calendar/schedule/ics/${fileid}`,
		calendar_schedule_csv: `${g.baseApiUrl}/file/download/calendar/schedule/csv/${fileid}`,
		calendar_company_ics: `${g.baseApiUrl}/file/download/calendar/company/ics/${fileid}`,
		calendar_company_csv: `${g.baseApiUrl}/file/download/calendar/company/csv/${fileid}`,
		calendar_hr_ics: `${g.baseApiUrl}/file/download/calendar/hr/ics/${fileid}`,
		calendar_hr_csv: `${g.baseApiUrl}/file/download/calendar/hr/csv/${fileid}`,
		calendar_custom_ics: `${g.baseApiUrl}/file/download/calendar/custom/ics/${fileid}`,
		calendar_custom_csv: `${g.baseApiUrl}/file/download/calendar/custom/csv/${fileid}`
	}

	// orientation
	var orientation = {
		expense: true,
		expensehistory: true,
		payrollperiod_pdf: true,
		payrollfile: false,
		purchasereceipt: false,
		recurringpaymentreceipt: false,
		projectrates_default: false,
		projectrates_user: false,
		projectrates_user_individual: false,
		accountcoderates_default: false,
		accountcoderates_user: false,
		accountcoderates_user_individual: false,
		calendar_schedule_ics: false,
		calendar_schedule_csv: false,
		calendar_company_ics: false,
		calendar_company_csv: false,
		calendar_hr_ics: false,
		calendar_hr_csv: false,
		calendar_custom_ics: false,
		calendar_custom_csv: false
	}

	// invoke
	if( orientation[type] ) {
		orientationDialog({
				api: urls[type],
				callbackwait: waitDialog,
				callbackfile: filefetch
			}
		);
	} else {
		waitDialog({
				api: urls[type],
				callbackfile: filefetch
			}
		);
	}
}

function orientationDialog( opts ) {
	var msgMakeSelection = getTranslation("JavaScript.Title.MakeASelection");
	var btnPortrait = getTranslation("Content.Btn.Portrait");
	var btnLandscape = getTranslation("Content.Btn.Landscape");

	// clears out dialog
	$("#download-dialog").html("");

	// orientation dialog
	$("#download-dialog").dialog({
		title: msgMakeSelection,
		width: 300,
		height: 200,
		autoOpen: true,
		modal: true,
		position: {
			my: "center center",
			at: "center center",
			of: window },
		buttons: [
			{
				text: btnPortrait,
				id: 'portraitButton',
				click: function(){
					closeDialog();
					opts.orientation = "PORTRAIT";
					opts.callbackwait.call( this, opts );
				}
			},
			{
				text: btnLandscape,
				id: 'landscapeButton',
				click: function(){
					closeDialog();
					opts.orientation = "LANDSCAPE";
					opts.callbackwait.call( this, opts );
				}
			}
		],
		open: function() { }
	});
}

function waitDialog( opts ) {
	var defaultTitle = getTranslation("JavaScript.ProcessingPleaseWait");

	// wait dialog
	$("#download-dialog").dialog({
		title: defaultTitle,
		width: 400,
		height: 350,
		autoOpen: true,
		position: {
			my: "center center",
			at: "center center",
			of: window },
		modal: true,
		buttons: [],
		open: function() {
			$(this).html('<div class="img-loading" style="width:100%;"></div>');
			opts.callbackfile.call( this, opts );
			}
		});
	}

function filefetch( opts ) {
	// console.log("filefetch( opts ) = ", opts);
	// vars
	var ResultMsg = '';
	var FileUrl = '';
	var RedirectUrl = '';

	// param operator
	var querydelimeter = opts.api.includes("?") ? "&" : "?";

	// defaults
	opts.orientation = opts.orientation || '';

	// url constructor
	opts.url = ( opts.orientation.length )
		? `${opts.api}${querydelimeter}Orientation=${opts.orientation}`
		: opts.api;

	// api fetch
	$.ajax({
		type: "GET",
		url: opts.url,
		dataType: "json",
	  	headers: createBaseApiHeaders(getApiKeyFromStorage(),getApiTokenFromStorage()),
		cache: false,
		success: function(res) {

			ResultMsg = getTranslation("JavaScript.FileReadyForDownloadClickHere");

			let btnEmailTxt = getTranslation("JavaScript.Btn.EmailLink");
			let btnCopyTxt = getTranslation("JavaScript.Btn.CopyLink");
			let orTxt = getTranslation("JavaScript.Or");

			if ( res.hasOwnProperty("data") && res.data.hasOwnProperty("FileURL") ){
				FileUrl = res.data.FileURL;
				RedirectUrl = res.data.RedirectURL;
			}
			else if ( res.hasOwnProperty("Data") && res.Data.hasOwnProperty("FileURL") ){
				FileUrl = res.Data.FileURL;
				RedirectUrl = res.Data.RedirectURL;
			}

			if ( res.MsgType === 'ERROR' || FileUrl === '' ) {
				errorDialog();
			} else {

				// required as the mailto will strip out query params if they are not encoded
				let emailFileUrl = RedirectUrl.replaceAll("?","%3F");
				emailFileUrl = RedirectUrl.replaceAll("&","%26");

				let emailTo = (opts.emailTo) ? opts.emailTo : "";

				let emailSub = (opts.emailSub) ? opts.emailSub : getTranslation("JavaScript.FileDownload.EmailSubject");
				let emailBody = getTranslation("JavaScript.FileDownload.EmailBody.LinkToFile") + '\n\n'
												+ emailFileUrl + '\n\n'
												+ getTranslation("JavaScript.FileDownload.EmailBody.FileAvailability");

				emailBody = encodeURIComponent(emailBody);

				let emailButton =
					`<button id="linkEmailBtn" onclick="window.location.href='`
					+ `mailto:` + emailTo
					+ `?subject=` + emailSub
					+	`&body=` + emailBody
					+ `'">` + btnEmailTxt + `</button>`;

				let copyLinkButton = `<button id="linkCopyBtn" onclick="copyToClipboardAsync('` + RedirectUrl + `')">`+ btnCopyTxt + `</button>`;
				let faCircleCheck = '<i class="fa-regular fa-circle-check font-green-dark fa-7x"></i>';

				$("span.ui-dialog-title").text(getTranslation("JavaScript.Title.FileReady"));

				$('#download-dialog').dialog({
						height: "auto", // auto resize to fit text/html
					})
					.html('<div class="downloaderIcon">'
						+ faCircleCheck
						+ '</div>'
						+ `<p class="downloadLink"><a href="${FileUrl}" target="_blank">`+ ResultMsg + `</a></p>`
						+ '<p class="orText">--&nbsp;' + orTxt + '&nbsp;--</p>'
						+ '<div class="downloadButtons"><div class="eButton">' + emailButton + '</div><div class="lButton">' + copyLinkButton + '<div id="clipboardText"></div></div></div>'
					);
			}
		},
		error: function(err) {
			errorDialog();
		}
	});
}

function errorDialog() {
	var ErrorOccured = getTranslation("JavaScript.ErrorOccured");
	var FileError = getTranslation("JavaScript.FileError");

	// close existing dialog boxes
	closeDialog();

	// orientation dialog
	$("#download-dialog").dialog({
		title: ErrorOccured,
		width: 350,
		height: "auto", // auto resize to fit text/html
		autoOpen: true,
		modal: true,
		position: {
			my: "center center",
			at: "center center",
			of: window },
		buttons: [],
		open: function() {
			FileError = getTranslation("JavaScript.FileError");
			$("span.ui-dialog-title").text(getTranslation("JavaScript.Title.FileError"));
			$('#download-dialog').dialog({ height: "auto" }).html('<div class="downloaderIcon"><i class="fa-regular fa-triangle-exclamation font-red fa-7x"></i></div><p class="downloadLink">' + ErrorOccured + '<br />' + FileError + '</p>');
			}
	});
}

const copyToClipboardAsync = str => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText){
  	let clipboardText = " Copied!"; 	// TODO: i18n
  	// make it "blink" if someone keeps clicking on the copy button
  	$("#clipboardText").fadeOut(500, function(){
  		$("#clipboardText").empty();
  		$("#clipboardText").append('<i class="fa-regular fa-check"></i>'+ clipboardText);
  		$("#clipboardText").fadeIn(500);
  	});

		return navigator.clipboard.writeText(str);
	}
	else {
		let clipboardTextFail = "The Clipboard API is not available."	// TODO: i18n
		$("#clipboardText").append(clipboardTextFail);
		return Promise.reject('The Clipboard API is not available.');
	}

};